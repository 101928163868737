import React, { useEffect, useState } from 'react';
import { MailOutlined } from '@ant-design/icons';
import { Form, Formik, Field, ErrorMessage, FormikHelpers } from 'formik';
import { ValuesEmail, emailSchema, initialStateEmail } from "../../utils"
import { requestSuccessMessage } from 'src/components/constants';
import { useCreateFormMutation } from 'src/graphql';
import { useGeneralContext } from 'src/components/context';
import { useLocation } from 'react-router';
import { SvgIcon } from 'src/components/ui/SvgIcon';
import XcelButton from 'src/components/ui/Button';
interface InputField {
    id: string;
    FormLabel: string;
    InputPlaceholder: string;
    errorMessage: string | null;
    inputType: string;
    isActive: boolean;
}

interface NewsletterProps {
    title: string;
    description: string;
    isActive: boolean;
    SubscribeForm?: InputField[];
}

const Newsletter: React.FC<NewsletterProps> = ({ title, description, isActive, SubscribeForm }) => {
    const [createForm] = useCreateFormMutation();
    const { messageApi } = useGeneralContext();
    const { pathname } = useLocation();
    const [footer] = document.getElementsByClassName('footerWrapper');
    const form = footer?.querySelector(".newsletterForm") as HTMLFormElement | null;
    const [newsLetterInput, setnewsLetterInput] = useState(false);

    const content = SubscribeForm?.filter((item: { FormLabel: any; }) => item.FormLabel).map((item: { FormLabel: string; }) => item.FormLabel as string);

    const buttonConfigs = [{
      color: '#fff',
      width: '222px',
      hoverFontColor: '#fff',
      backgroundColor: '#2251FF',
      borderColor: 'transparent',
      hoverBorderColor: '#fff',
      focusColor: '',
      hoverColor: 'transparent',
      clickColor: '',
      iconFillColor: '',
      hoverIconFillColor:'', 
      iconStrokeColor:'',
      hoverIconStrokeColor: '',
    }]

    useEffect(() => {
        if (form) {
            form.reset();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pathname]);

    const onSubmitEmail = (values: ValuesEmail, formikBag: FormikHelpers<ValuesEmail>): void => {
        createForm({ variables: { data: { email: values.email } } })
            .then(res => console.debug(res))
            .catch(err => console.log(err));
        setnewsLetterInput(true);
        formikBag.resetForm();
        messageApi?.open({ type: "success", content: requestSuccessMessage });
    }

    return (
        <React.Fragment>
            <div className={"container"}>
                {
                    isActive && (
                        <div className={"footerNewsletter"}>
                            <div className={"newsletterContent"}>
                                <h4 className={"newsletterTitle"}>{title}</h4>
                                <p className={"newsletterDesc"}>{description}</p>
                            </div>
                            <Formik
                                initialValues={initialStateEmail}
                                onSubmit={onSubmitEmail}
                                validationSchema={emailSchema}
                                validateOnBlur={false}
                            >
                                <Form className={"newsletterForm"} id={"form"}>
                                    <div className={"newsletterForm--container"}>
                                        <div className={"newsletterFormWrapper"}>
                                            {SubscribeForm?.map((field) => (
                                                field.isActive && (
                                                    <React.Fragment key={field.id}>
                                                        {field.inputType !== 'submit' ? (
                                                            <div>
                                                                {/* <SvgIcon type={"Mailbox"} className={"mailBoxIcon"} /> */}
                                                                <Field
                                                                    type={field.inputType}
                                                                    name={field.inputType}
                                                                    placeholder={field.InputPlaceholder}
                                                                    className={"newsletterInput"}
                                                                />
                                                                {
                                                                    newsLetterInput ? (
                                                                        <span className={"successMessage"}>You have been subscribe with xcelpros</span>
                                                                    ): (
                                                                        
                                                                        <ErrorMessage name={field.inputType} component={"div"} className={"errorMessage"} />
                                                                    )
                                                                }
                                                            </div>
                                                        ) : (
                                                            // <button type={"submit"} className={"newsletterBtn"}>
                                                            //     {field.FormLabel}
                                                            // </button>
                                                            <XcelButton buttonsCSS={buttonConfigs}
                                                                content={field.FormLabel}
                                                                type={'submit'}                                                                 />
                                                        )}
                                                    </React.Fragment>
                                                    
                                                )
                                            ))}
                                        </div>
                                    </div>
                                </Form>
                            </Formik>
                        </div>
                    )
                }
            </div>
        </React.Fragment>

    );
};

export default Newsletter;
