import React from "react";
import { NavLink } from "react-router-dom";
import { logoProps } from "./GlobalTypes";

const HeaderLogo: React.FC<logoProps> = ({ logo }) => {
    if (!logo || !logo.lightLogo || !logo.lightLogo.data || !logo.lightLogo.data.attributes) {
        return null;
    }
    const { url } = logo.lightLogo.data.attributes;

    return (
        <NavLink to={"/"}>
            <img src={url} alt={logo.Title} />
        </NavLink>
    );
};

export default HeaderLogo;
