import { ErrorMessage, Field } from "formik"
import { CSSProperties, FC } from "react"
import { Link } from "../Link"
interface Props {
  placeholderClassName?: string
  input: Maybe<InputFragment>
  classNameLabel?: string
  style?: CSSProperties
}

const Input: FC<Props> = ({ input, placeholderClassName, style, classNameLabel }) => {
  const labelElement = <span className={classNameLabel}>{input?.label}</span>
  const labelCheckboxElement = (name: Maybe<string>) =>
    name === "receiveUpdates" ? (
      <span className={"avgTextSm"}>
        Get the latest market updates, business opportunities and ideas delivered right to your inbox. We will not share
        your details with any third party at any time. For more details View our
        <Link link={{ url: "/privacy-policy", title: "  Privacy Policy." }} className={"privacyLabel"} />
      </span>
    ) : (
      <span className={"avgTextSmBook"}>
        I agree to 
        <Link link={{ url: "/privacy-policy", title: "  privacy policy & terms" }} className={"privacyLabel"} />
      </span>
    )
  const errorElement = <ErrorMessage name={input?.name|| ""} className={"error"} component={"span"} />

  return (
    <div className={input?.isFullWidth ? "inputWrapper" : "inputWrapperHalfWidth"}>
      {input?.type === "checkbox" ? (
        <label className={"labelCheckbox"}>
          <Field type={"checkbox"} name={input?.name} className={"checkbox"} />
          {labelCheckboxElement(input?.name)}
          {errorElement}
        </label>
      ) : (
        <label className={"label"} style={style}>
          {labelElement}
          <Field
            type={input?.type}
            name={input?.name}
            placeholder={input?.placeholder}
            className={`input radius ${placeholderClassName}`}
          />
          {errorElement}
        </label>
      )}
    </div>
  )
}

export { Input }
