import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { LinksMain, MenuIcon, MenuCta, logoProps } from './GlobalTypes';
import { RightOutlined, LeftOutlined } from '@ant-design/icons';

import './HamburgerMenu.less';
import HeaderLogo from './Logo';
import { SvgIcon } from '../ui/SvgIcon';

interface HamburgerMenuProps {
    mainMenuList: LinksMain[];
    logo: any;
    menuIcons: MenuIcon;
    menuCtas: MenuCta;
}

const HamburgerMenu: React.FC<HamburgerMenuProps> = ({ mainMenuList, logo, menuIcons, menuCtas }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [openDropdown, setOpenDropdown] = useState<string | null>(null);
    const [headerTop, setHeaderTop] = useState('38px');

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 0 && !isOpen) {
                setHeaderTop('0px');
            } else {
                setHeaderTop('38px');
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [isOpen]);

    useEffect(() => {
        if (isOpen) {
            document.body.classList.add('no-scroll');
            setHeaderTop('0px');
        } else {
            document.body.classList.remove('no-scroll');
        }

        return () => {
            document.body.classList.remove('no-scroll');
        };
    }, [isOpen]);

    const toggleMenu = () => {
        setIsOpen(!isOpen);
        setOpenDropdown(null);
    };

    const toggleDropdown = (id: string) => {
        setOpenDropdown(prevState => (prevState === id ? null : id));
    };

    const closeDropdown = () => {
        setOpenDropdown(null);
    };

    const handleLinkClick = () => {
        setIsOpen(false);
        setOpenDropdown(null);
    };
console.log("Hamburger Menu List" + JSON.stringify(mainMenuList, null, 2));
    return (
        <div className={"hamburger-header-section"} style={{ top: headerTop }}>
            <div className={"container"}>
                <div className={"hamburgerWraper"}>
                    {openDropdown ? (
                        <button className={"back-button"} onClick={closeDropdown}>
                            <SvgIcon type={"Chevron"} className={"leftChevron"} />
                        </button>
                    ) : (
                        <div className={"main-logo"}>
                            <HeaderLogo logo={logo} />
                        </div>

                    )}
                    <div
                        className={`hamburger-menu ${isOpen ? 'open' : ''}`}
                        onClick={toggleMenu}
                    >
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                </div>
                <div
                    className={`mobile-menu ${isOpen ? 'open' : ''}`}
                >
                    <ul className={openDropdown ? 'active-tab' : 'inactive-tab'}>
                        {mainMenuList.filter(link => link?.isActive).map(link => (
                            <li
                                key={link.id}
                                className={`nav-items ${openDropdown === link.id ? 'active' : ''}`}
                            >
                                <React.Fragment>
                                    {link?.Redirect ? (
                                        <Link className={"main-nav-link"} to={link.Redirect} onClick={handleLinkClick}>
                                            {link?.Icon?.data?.attributes?.url && <img className={"main-nav-icon"} src={link?.Icon?.data?.attributes?.url} alt={link?.Title} />}
                                            <span className={"main-nav-items"}>{link?.Title}</span>
                                        </Link>
                                    ) : (
                                        <span className={"main-nav-link"} onClick={handleLinkClick}>
                                            {link?.Icon?.data?.attributes?.url && <img className={"main-nav-icon"} src={link?.Icon?.data?.attributes?.url} alt={link?.Title} />}
                                            <span className={"main-nav-items"}>{link?.Title}</span>
                                        </span>
                                    )}
                                    {link?.isDropDown && (
                                        <button className={"dropdown-toggle"} onClick={() => toggleDropdown(link.id)}>
                                            <SvgIcon type={"Chevron"} className={"RightChevron"} />
                                        </button>
                                    )}
                                </React.Fragment>
                                {openDropdown === link.id && (
                                    <React.Fragment>
                                        <ul className={"drop-list"}>
                                            {link?.drop_menu_lists?.data?.map(dropLink => (
                                                <li key={dropLink.id} className={"drop-list-items"}>
                                                    {dropLink?.attributes?.Redirect ? (
                                                        <Link to={dropLink.attributes.Redirect} className={"drop-list-items--title"} onClick={handleLinkClick}>
                                                            {dropLink?.attributes?.Icon?.data?.attributes?.url && <img src={dropLink?.attributes?.Icon?.data?.attributes?.url} alt={dropLink?.attributes?.Title} />}
                                                            <span className={"drop-list-item-span"}>{dropLink?.attributes?.Title}</span>
                                                        </Link>
                                                    ) : (
                                                        <span className={"drop-list-items--title"} onClick={handleLinkClick}>
                                                            {dropLink?.attributes?.Icon?.data?.attributes?.url && <img src={dropLink?.attributes?.Icon?.data?.attributes?.url} alt={dropLink?.attributes?.Title} />}
                                                            <span className={"drop-list-item-span"}>{dropLink?.attributes?.Title}</span>
                                                        </span>
                                                    )}
                                                    {dropLink?.attributes?.dropLinks && (
                                                        <ul className={"nested-drop-list"}>
                                                            {dropLink?.attributes?.dropLinks?.map(nestedDropLink => (
                                                                <li key={nestedDropLink.id} className={"nested-drop-list-items"}>
                                                                    {nestedDropLink?.Redirect ? (
                                                                        <Link to={nestedDropLink.Redirect} onClick={handleLinkClick}>
                                                                            {nestedDropLink?.Icon?.data?.attributes?.url && <img src={nestedDropLink?.Icon?.data?.attributes?.url} alt={nestedDropLink?.Title} />}
                                                                            <span className={"nested-drop-list-item-span"}>{nestedDropLink?.Title}</span>
                                                                        </Link>
                                                                    ) : (
                                                                        <span onClick={handleLinkClick}>
                                                                            {nestedDropLink?.Icon?.data?.attributes?.url && <img src={nestedDropLink?.Icon?.data?.attributes?.url} alt={nestedDropLink?.Title} />}
                                                                            <span className={"nested-drop-list-item-span"}>{nestedDropLink?.Title}</span>
                                                                        </span>
                                                                    )}
                                                                </li>
                                                            ))}
                                                        </ul>
                                                    )}
                                                </li>
                                            ))}
                                            {link?.drop_menu_list_secondaries?.data?.map(dropLink => (
                                                <li key={dropLink.id} className={"drop-list-items"}>
                                                    {dropLink?.attributes?.Redirect ? (
                                                        <Link to={dropLink.attributes.Redirect} className={"drop-list-items--title"} onClick={handleLinkClick}>
                                                            {dropLink?.attributes?.Icon?.data?.attributes?.url && <img src={dropLink?.attributes?.Icon?.data?.attributes?.url} alt={dropLink?.attributes?.Title} />}
                                                            <span className={"drop-list-item-span"}>{dropLink?.attributes?.Title}</span>
                                                        </Link>
                                                    ) : (
                                                        <span className={"drop-list-items--title"} onClick={handleLinkClick}>
                                                            {dropLink?.attributes?.Icon?.data?.attributes?.url && <img src={dropLink?.attributes?.Icon?.data?.attributes?.url} alt={dropLink?.attributes?.Title} />}
                                                            <span className={"drop-list-item-span"}>{dropLink?.attributes?.Title}</span>
                                                        </span>
                                                    )}
                                                    {dropLink?.attributes?.dropLinks && (
                                                        <ul className={"nested-drop-list"}>
                                                            {dropLink?.attributes?.dropLinks?.map(nestedDropLink => (
                                                                <li key={nestedDropLink.id} className={"nested-drop-list-items"}>
                                                                    {nestedDropLink?.Redirect ? (
                                                                        <Link to={nestedDropLink.Redirect} onClick={handleLinkClick}>
                                                                            {nestedDropLink?.Icon?.data?.attributes?.url && <img src={nestedDropLink?.Icon?.data?.attributes?.url} alt={nestedDropLink?.Title} />}
                                                                            <span className={"nested-drop-list-item-span"}>{nestedDropLink?.Title}</span>
                                                                        </Link>
                                                                    ) : (
                                                                        <span onClick={handleLinkClick}>
                                                                            {nestedDropLink?.Icon?.data?.attributes?.url && <img src={nestedDropLink?.Icon?.data?.attributes?.url} alt={nestedDropLink?.Title} />}
                                                                            <span className={"nested-drop-list-item-span"}>{nestedDropLink?.Title}</span>
                                                                        </span>
                                                                    )}
                                                                </li>
                                                            ))}
                                                        </ul>
                                                    )}
                                                </li>
                                            ))}
                                        </ul>
                                        <div className={"card-container"}>
                                            {link?.drop_cards?.data?.map(card => {
                                                const { redirect, Banner, Title, Description } = card?.attributes || {};
                                                const cardContent = (
                                                    <div className={"card"} key={card.id}>
                                                        <div className={"cardImg"}>
                                                            <img src={Banner?.data?.attributes?.url ?? ''} alt={Title ?? ''} className={"card-image"} />
                                                        </div>
                                                        <div className={"cardContent"}>
                                                            <h3 className={"card-title"}>{Title}</h3>
                                                            <p className={"card-description"}>{Description}</p>
                                                        </div>
                                                    </div>
                                                );

                                                if (redirect && redirect.trim() !== "") {
                                                    return (
                                                        <Link to={redirect} key={card.id} onClick={handleLinkClick}>
                                                            {cardContent}
                                                        </Link>
                                                    );
                                                } else {
                                                    return (
                                                        <React.Fragment key={card.id}>
                                                            {cardContent}
                                                        </React.Fragment>
                                                    );
                                                }
                                            })}
                                        </div>
                                    </React.Fragment>
                                )}
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
        </div>
    );
};

export default HamburgerMenu;
